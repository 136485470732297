import React from 'react'
import { graphql } from 'gatsby'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from 'layouts/index'

const Page404 = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <React.Fragment>
        <section className="flex items-center h-full p-16">
          <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
            <div className="max-w-md text-center">
              <h2 className="mb-4 font-extrabold text-9xl text-primary-100">
                <span className="sr-only">{t('404Page.srTitle')}</span>404
              </h2>
              <div className="flex justify-center">
                <img src="https://www.mlytics.com/wp-content/uploads/2020/11/mlytics-kitty.jpg" width="240" />
              </div>
              <p className="text-2xl font-semibold md:text-3xl text-primary-600">{t('404Page.subtitle')}</p>
              <p className="mt-4 mb-8 text-gray-800">{t('404Page.description')}</p>
              <Link to="/">
                <button className="btn btn-orange mt-2 px-2 py-7">{t('404Page.buttonText')}</button>
              </Link>
            </div>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default Page404

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
